import React, {Component} from 'react';
import {useTranslation} from 'react-i18next';
import SlideAnimation from '../../../../animations/SlideAnimation';
import './Hero.scss';
export function Hero() {
  const {t} = useTranslation();
  return (
    <div className="Hero-Container">
      <SlideAnimation
        xStart={-200}
        duration={1.2}
        node={'Hero-Heading-Container'}
      >
        <div
          className="Hero-Heading-Container">
          <div className="Hero-Heading-1">{t('heroOne')}</div>
          <div className="Hero-Heading-2">Berwyn, Illinois</div>
        </div>
      </SlideAnimation>
    </div>
  );
}

export default Hero;