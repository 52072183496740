import React, {Component} from 'react';
import * as Components from './components';
import './Budget.scss';
import ReactGA from 'react-ga4';
import PropTypes from 'prop-types';

export class Budget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptcha: ''
    };
  }
  componentDidMount() {
    this.setState({recaptcha: ''});
  }
  changeRecaptcha = (change) => {
    this.setState({recaptcha: change});
  };
  render() {
    const votingOptions = [
      'Project A: 5th Ward Picnic',
      'Project B: Intergenerational Fun',
      'Project C: Native Plant Club',
      'Project D: Civic Leadership Training',
      'Project E: Youth Civic Spirit Award',
    ];
    if (this.state.recaptcha === '') {
      ReactGA.send({hitType: 'pageview', page: '/recaptcha', title: 'Recaptcha'});
      return <Components.CheckUser changeRecaptcha={this.changeRecaptcha} />;
    } else if (this.state.recaptcha !== '') {
      ReactGA.send({hitType: 'pageview', page: '/Budget', title: 'Budget'});
      return (
        <div
          className='Budget-Page'
        >
          <div className='Budget-Introduction'>
            <h1 className='Budget-Header-Top'>Berwyn's 5th Ward Participatory Budgeting 2024</h1>
          </div>
          <section className='Budget-Main-Voting-Section'>
            <Components.OptionsInfo
              votingOptions={votingOptions}
            />
            <Components.VotingForm
              votingOptions={votingOptions}
            />
          </section>
        </div>
      );
      
    }
  }
}

Budget.propTypes = {
  app: PropTypes.object
};

export default Budget;