export const spanishTranslation = {
  // Nav Bar
  navOne: 'Ward 5 de Berwyn',
  navTwo: 'Acerca de',
  navThree: 'Contacto',
  navFour: 'Servicios',
  // Home Page
  heroOne: 'El Quinto Ward',
  eventsHeader: 'Eventos De Ward',
  contactButton: 'Enviar Envento',
  // About Page
  paraOne: 'Rob fue elegido concejal del quinto distrito en abril de 2021. Antes de comenzar su mandato de cuatro años el 11 de mayo de 2021, Rob sirvió en la Junta de Educación del Distrito Escolar 98 de Berwyn North. También es cofundador de Berwyn Free Library Initiative, una organización de base que promueve la alfabetización bilingüe, la construcción de comunidades y el acceso a recursos para todos. Rob es un consumado líder sin fines de lucro y organizador comunitario que ha dedicado su vida y carrera a crear oportunidades para otros y hacer que la gente pase a la acción.',
  paraTwo: 'Rob comenzó su carrera en el sector sin ánimo de lucro trabajando en desarrollo juvenil y servicios profesionales. Pasó varios años trabajando con jóvenes de la zona de Berwyn/Cicero y durante ese tiempo entrenó a jóvenes adultos para que descubrieran su trayectoria profesional, se formó como cuidador de círculos y se desarrolló como facilitador.',
  paraThree: 'En su puesto actual en Chicago Cares, trabaja en el equipo de relaciones cívicas y comunitarias, donde se asocia con organizaciones de toda la ciudad de Chicago para apoyar sus planes de calidad de vida y movilizar a los voluntarios para la acción cívica.',
  paraFour: 'Rob es un residente de Berwyn de toda la vida y un orgulloso líder Latinx, que se ha comprometido a utilizar sus recursos y dones para inspirar a más personas - especialmente aquellos que no suelen estar representados - para tener un asiento y voz en la mesa. Rob vive en el lado norte de Berwyn, con su esposa y sus tres hijos.',
  // Contact Page 
  contactHeader: 'Póngase en contacto con el Alderman',
  formInputOne: 'Nombre',
  formInputTwo: 'Correo electrónico',
  formInputThree: 'Dirección completa',
  formInputFour: 'Teléfono',
  formInputSelect: 'Tema',
  formInputMessage: 'Mensaje',
  formSubmit: 'Enviar',
  thankYou: 'Gracias!',
  // Services Page
  serviceOne: 'Factura del agua',
  serviceTwo: 'Fiesta del Bloque',
  serviceThree: 'Gestión de residuos',
  serviceFour: 'Solicitar un árbol',
  serviceFive: 'Inscribirse para votar',
  serviceSix: 'Servicios para mayores',
  serviceSeven: 'Desratización',
  serviceEight: 'Recursos fiscales',
  // Budgeting 2024
  projectA1: 'Proyecto A: Picnic en el quinto distrito',
  projectA2: 'Un picnic comunitario para residentes y amigos del quinto distrito. El picnic incluiría actividades familiares, música y diversión para todas las edades.',
  projectACost: '$2,000',
  projectB1: 'Proyecto B: Diversión intergeneracional',
  projectB2: 'Una serie de eventos para que los adultos mayores y los jóvenes de la comunidad se reúnan, establezcan conexiones y se apoyen unos a otros.',
  projectBCost: '$1,500',
  projectC1: 'Proyecto C: Club de plantas nativas',
  projectC2: 'Financiamiento y apoyo para iniciar un club de plantas nativas en nuestro vecindario. Esto incluiría plantas, educación sobre plantas nativas y proyectos de embellecimiento en las esquinas de las calles.',
  projectCCost: '$1,500',
  projectD1: 'Proyecto D: Entrenamiento en liderazgo cívico',
  projectD2: 'Financiamiento para entrenar a voluntarios sobre cómo actuar como observadores judiciales. El entrenamiento incluiría mejores prácticas y recursos para apoyar a los residentes que enfrentan desafíos legales o financieros.',
  projectDCost: '$1,000',
  projectE1: 'Proyecto E: Premio al Espíritu Cívico Juvenil',
  projectE2: 'Una beca para un estudiante de octavo grado en Berwyn que encarna el espíritu cívico, el servicio y la comunidad. Los maestros, administradores, entrenadores y líderes comunitarios pueden nominar a un joven para este premio.',
  projectECost: '$750',
};