import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './Map.scss';

export default function Map() {
  return (
    <div className="Map-Body-Container">
      <div className="Map-Body-Section">
        <div className="Map-Area-Left">
          <Zoom>
            <img className='Ward-Map' src="/ward_map.jpeg" alt="Ward Map" />
            <figcaption className='Map-Artist'>
              Map Artist:{' '}
              <a
                target="_blank"
                className='Map-Artist-Link'
                href='https://www.instagram.com/saltysaidsweetly/'>
                Kris McDermott
              </a>
            </figcaption>
          </Zoom>
        </div>
        <div className="Map-Area-Right">
          <Zoom>
            <img className='Ward-Legend' src="/ward_legend.jpeg" alt="Map Legend" />
          </Zoom>
        </div>
      </div>
    </div>
  );
}
