import React, {Component} from 'react';
import './Home.scss';
import * as Components from './components';
// Google Analytics
import ReactGA from 'react-ga4';

export class Home extends Component {
  render() {
    ReactGA.send({hitType: 'pageview', page: '/', title: 'Home'});
    return (
      <div className='Home-Container'>
        <Components.Hero />
        <Components.Body />
        <Components.Map />
      </div>
    );
  }
}

export default Home;