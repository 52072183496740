import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {englishTranslation} from './Languages/english';
import {spanishTranslation} from './Languages/spanish';
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: englishTranslation
      },
      sp: {
        translation: spanishTranslation
      },
    },
  });
