import React from 'react';
import './AboutBody.scss';
import aldermanLogo from '../../../../assets/images/rjp logo.png';
import aldermanImg from '../../../../assets/images/alderman.jpg';
import SlideAnimation from '../../../../animations/SlideAnimation';
import {useTranslation} from 'react-i18next';
export default function AboutBody() {
  const {t} = useTranslation();
  return (
    <div className="About-Body-Container">
      <section
        id="First-Section"
        className="Body-Section"
      >
        <SlideAnimation
          xStart={-150}
          duration={1.2}
          node={'Left-Area'}
        >
          <div className="Left-Area">
            <img
              className="Alderman-Logo"
              src={aldermanLogo}
            />
            {/* <div className='Header-1'>Meet Our Alderman</div> */}
            <img
              className="Alderman-Image"
              src={aldermanImg}
            />
          </div>
        </SlideAnimation>
        <SlideAnimation
          yStart={150}
          duration={1.2}
          node={'Right-Area'}
        >
          <div className="Right-Area">
            <p>{t('paraOne')}</p>
            <p>{t('paraTwo')}</p>
            <p>{t('paraThree')}</p>
            <p>{t('paraFour')}</p>
          </div>
        </SlideAnimation>
      </section>
    </div>
  );
}
