import React from 'react';
import PropTypes from 'prop-types';
import {getDatabase, get, child, ref, update} from 'firebase/database';

function EventCard(props) {
  const db = getDatabase();
  const dbRef = ref(db);
  const startTime = props.event.startTime
    ? `${props.convertTime(props.event.startTime)[1]}${props.convertTime(props.event.startTime)[2]}`
    : 'Start Time Not Provided';
  const changeEventStatus = (item) => {
    get(child(dbRef, 'events/')).then((snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
          if (childData.userEmail === item.userEmail && childData.description === item.description) {
            update(ref(db, 'events/' + `${childKey}/`), {
              status: childData.status === 'applied' ? 'approved' :
                childData.status === 'approved' ? 'removed' :
                  childData.status === 'removed' ? 'applied' : 'applied'
            });
          }
        });
      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error(error);
    });
  };
  return (
    <div
      className='Dash-Card'
    >
      <div className="Dash-Card-Name">{props.event.name}</div>
      <div className="Dash-Card-Cell">
        {startTime}
      </div>
      <div className="Dash-Card-Cell">{props.event.date ? props.event.date : 'Data Error'}</div>
      <div className="Dash-Card-Cell">{props.event.address}</div>
      <div className="Dash-Card-Cell">{props.event.description}</div>
      <a
        target="_blank" href={props.event.url} className="Dash-Card-Link" rel="noreferrer">
        Event Link
      </a>
      <button
        onClick={() => changeEventStatus(props.event)}
        className={
          props.event.status === 'applied' ? 'Dash-Card-Status-1' : 
            props.event.status === 'approved' ? 'Dash-Card-Status-2' :
              props.event.status === 'removed' ? 'Dash-Card-Status-3' :
                'Dash-Card-Status-4'
        }
      >
        {props.event.status ? props.event.status : 'Data Error'}
      </button>
      <button
        onClick={() => props.archiveItem(props.event)}
        className="Dash-Card-Delete"
      >
        Archive
      </button>
      <button
        onClick={() => props.deleteItem(props.event, 'events')}
        className="Dash-Card-Delete"
      >
        Delete
      </button>
    </div>
  );    
}

EventCard.propTypes = {
  event: PropTypes.object,
  deleteItem: PropTypes.func,
  cardType: PropTypes.string,
  convertTime: PropTypes.func,
  changeEventStatus: PropTypes.func,
};

export default EventCard;
