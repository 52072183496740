import React from 'react';
import './Services.scss';
import {
  FiDroplet,
  FiCalendar,
  FiTrash2,
  FiTriangle,
  FiCheckSquare,
  FiUsers,
  FiTarget,
  FiFileText
} from 'react-icons/fi';
import SlideAnimation from '../../animations/SlideAnimation';
import {useTranslation} from 'react-i18next';
import ReactGA from 'react-ga4';

export default function Services() {
  const {t} = useTranslation();
  ReactGA.send({hitType: 'pageview', page: '/services', title: 'Services'});
  return (
    <div className='Services-Page'>
      <div className='Services-Grid'>
        <SlideAnimation
          xStart={-50}
          duration={0.8}
          node={'Service-Item'}
        >
          <div className="Service-Item"
            onClick={() =>
              window.open('https://magic.collectorsolutions.com/magic-ui/OneTimePayment/city-of-berwyn', '_blank')}
          >
            {t('serviceOne')}
            <FiDroplet
              size={60}
            />
          </div>
        </SlideAnimation>
        <SlideAnimation
          xStart={-50}
          duration={0.8}
          node={'Service-Item'}
        >
          <div className="Service-Item"
            onClick={() =>
              window.open('https://www.berwyn-il.gov/home/showpublisheddocument/160/637852835983200000', '_blank')}
          >
            {t('serviceTwo')}
            <FiCalendar
              size={60}
            />
          </div>
        </SlideAnimation>
        <SlideAnimation
          xStart={-50}
          duration={0.8}
          node={'Service-Item'}
        >
          <div className="Service-Item"
            onClick={() =>
              window.open('https://www.sbcwastesolutions.com/berwyn/', '_blank')}
          >
            {t('serviceThree')}
            <FiTrash2
              size={60}
            />
          </div>
        </SlideAnimation>
        <SlideAnimation
          xStart={-50}
          duration={0.8}
          node={'Service-Item'}
        >
          <div className="Service-Item"
            onClick={() => window.open('https://berwyntrees.org/plant-more-trees/', '_blank')}
          >
            {t('serviceFour')}
            <FiTriangle
              size={60}
            />
          </div>
        </SlideAnimation>
        <SlideAnimation
          xStart={-50}
          duration={0.8}
          node={'Service-Item'}
        >
          <div className="Service-Item"
            onClick={() => window.open('https://ova.elections.il.gov/', '_blank')}
          >
            {t('serviceFive')}
            <FiCheckSquare
              size={60}
            />
          </div>
        </SlideAnimation>
        <SlideAnimation
          xStart={-50}
          duration={0.8}
          node={'Service-Item'}
        >
          <div className="Service-Item"
            onClick={() => window.open('https://www.berwyn-il.gov/government/departmental-directory/senior-services', '_blank')}
          >
            {t('serviceSix')}
            <FiUsers
              size={60}
            />
          </div>
        </SlideAnimation>
        <div className="Service-Item"
          onClick={() => window.open('https://cityofberwyn.formstack.com/forms/blight', '_blank')}
        >
          {t('serviceSeven')}
          <FiTarget
            size={60}
          />
        </div>
        <SlideAnimation
          xStart={-50}
          duration={0.8}
          node={'Service-Item'}
        >
          <div className="Service-Item"
            onClick={() => window.open('https://www.berwynassessor.org/', '_blank')}
          >
            {t('serviceEight')}
            <FiFileText
              size={60}
            />
          </div>
        </SlideAnimation>
      </div>
    </div>
  );
}
