import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import SlideAnimation from '../../animations/SlideAnimation';
import './Navigator.scss';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import {FiSidebar, FiChevronsRight} from 'react-icons/fi';

export default function Navigator() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const {t} = useTranslation();
  const setLanguage = (option) => {
    i18next.changeLanguage(option.target.value);
  };
  return (
    <div className='Navigator-Container'>
      <SlideAnimation
        xStart={-150}
        duration={1}
        node={'Nav-Bar'}
      >
        <div className="Nav-Bar">
          <Link className='Nav-Link-Logo' to={'/'}>{t('navOne')}</Link>
          <Link className='Nav-Link' to={'/about'}>{t('navTwo')}</Link>
          <Link className='Nav-Link' to={'/contact'}>{t('navThree')}</Link>
          <Link className='Nav-Link' to={'/services'}>{t('navFour')}</Link>
        </div>
      </SlideAnimation>
      <select
        className='Language-Select'
        onChange={(change) => setLanguage(change)}>
        <option
          value='en'
        >
          English
        </option>
        <option
          value='sp'
        >
          Español
        </option>
      </select>
      <div className='Nav-Mobile'>
        <Link className='Nav-Link-Logo' to={'/'}>5th Ward Berwyn, IL</Link>
        <button
          className='Nav-Mobile-Burger'
          onClick={() => setMobileOpen(!mobileOpen)}>
          <FiSidebar size={30} color={'white'} />
        </button>
        {mobileOpen ? (
          <div className="Nav-Mobile-Sidebar">
            <button
              className='Nav-Mobile-Burger'
              onClick={() => setMobileOpen(!mobileOpen)}>
              <FiChevronsRight size={30} color={'white'} />
            </button>
            <Link
              onClick={() => setMobileOpen(false)}
              className='Nav-Mobile-Link' to={'/'}>
              Home
            </Link>
            <Link
              onClick={() => setMobileOpen(false)}
              className='Nav-Mobile-Link' to={'/about'}>
              About
            </Link>
            <Link
              onClick={() => setMobileOpen(false)}
              className='Nav-Mobile-Link' to={'/contact'}>
              Contact
            </Link>
            <Link
              onClick={() => setMobileOpen(false)}
              className='Nav-Mobile-Link' to={'/services'}>
              Services
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}
