import React, {useState, useEffect} from 'react';
import './Dashboard.scss';
import {getDatabase, get, child, ref, remove, onValue, update} from 'firebase/database';
import Login from './Login';
import {uuidv4} from '@firebase/util';
import {getAuth} from 'firebase/auth';
import {DateTime} from 'luxon';
import ContactCard from './ContactCard';
import EventCard from './EventCard';
import SlideAnimation from '../../animations/SlideAnimation';
import EventsCalendar from './EventsCalendar';

function Dashboard() {
  const [contactData, setContactData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [userVerified, setUserVerified] = useState(false);
  const [accessLevel, setAccessLevel] = useState('');
  const [currentView, setCurrentView] = useState('submissions');
  const [dashFilter, setDashFilter] = useState('');
  const auth = getAuth();
  const db = getDatabase();
  const dbRef = ref(db);
  // GET USER DATA
  useEffect(() => {
    auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        // LIVE DATA
        // * CONTACT SUBMISSION DATA
        const submissions = ref(db, 'contact-submissions/');
        onValue(submissions, (snapshot) => {
          setContactData(Object.values(snapshot.val()));
        });
        // * EVENT DATA
        const events = ref(db, 'events/');
        onValue(events, (snapshot) => {
          setEventsData(Object.values(snapshot.val()));
        });
        get(child(dbRef, 'users/' + auth.currentUser.uid)).then((snapshot) => {
          if (snapshot.exists()) {
            setAccessLevel(snapshot.val().accessLevel);
          } else {
            console.log('No data available');
          }
        }).catch((error) => {
          console.error(error);
        });
      }
    });
    return () => {
      setUserVerified(false);
      setDashFilter('');
      setCurrentView('submissions');
    };
  }, []);
  function convertTime(time) {
    if (time) {
      return DateTime.fromFormat(time, 'HH:mm').toFormat('h:mm t').split(' ');
    } else if (!time) {
      console.log(time);
    }
  }
  // Menu Events
  const changeVerification = () => {
    if (userVerified) {
      auth.signOut();
      setUserVerified(false);
    } else if (!userVerified) {
      setUserVerified(!userVerified);
    }
  };
  const seeEvents = () => {
    setCurrentView('events');
  };
  const seeSubmissions = () => {
    setCurrentView('submissions');
  };
  const seeCalendar = () => {
    setCurrentView('calendar');
  };
  // ---------
  // Edit Data Functions
  const archiveItem = (item) => {
    if (confirm('Are you sure you want to archive this?')) {
      get(child(dbRef, 'events/')).then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val();
            if (childData.userEmail === item.userEmail && childData.description === item.description) {
              update(ref(db, 'events/' + `${childKey}/`), {
                status: 'archived'
              });
            }
          });
        } else {
          console.log('No data available');
        }
      }).catch((error) => {
        console.error(error);
      });
    }
  };
  const deleteItem = (item, type) => {
    if (confirm('Are you sure you want to delete this?')) {
      if (type === 'contacts') {
        get(child(dbRef, 'contact-submissions/')).then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((childSnapshot) => {
              const childKey = childSnapshot.key;
              const childData = childSnapshot.val();
              if (childData.email === item.email && childData.timeStamp === item.timeStamp) {
                const deletedMessage = ref(db, 'contact-submissions/' + `${childKey}/`);
                remove(deletedMessage).then(() => {
                  alert('Message Deleted');
                });
              }
            });
          } else {
            console.log('No data available');
          }
        }).catch((error) => {
          console.error(error);
        });
      } else if (type === 'events') {
        get(child(dbRef, 'events/')).then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((childSnapshot) => {
              const childKey = childSnapshot.key;
              const childData = childSnapshot.val();
              if (childData.userEmail === item.userEmail && childData.description === item.description) {
                const deletedMessage = ref(db, 'events/' + `${childKey}/`);
                remove(deletedMessage).then(() => {
                  alert('Message Deleted');
                });
              }
            });
          } else {
            console.log('No data available');
          }
        }).catch((error) => {
          console.error(error);
        });
      }
    }
  };
  // ---------
  const ContactDataRender = (filter) => {
    return contactData.map((contact, index) =>
      contact.topic.toLowerCase().includes(filter.toLowerCase())
        || contact.message.toLowerCase().includes(filter.toLowerCase())
        || contact.name.toLowerCase().includes(filter.toLowerCase())
        ?
        (
          <div
            className='Card-Container'
            key={uuidv4()}>
            <ContactCard
              index={index}
              contact={contact}
              cardType={'contact'}
              deleteItem={deleteItem}
            />
          </div>
        ) :
        null
    );
  };

  const EventsDataRender = (filter) => {
    try {
      const sortedEventsData =     // Sort events by the absolute difference between their dates and today
        eventsData.sort((a, b) => {
          const aDate = new Date(a.date);
          const bDate = new Date(b.date);
          const today = new Date();

          // Calculate absolute difference in milliseconds for precise sorting
          const diffA = Math.abs(aDate - today);
          const diffB = Math.abs(bDate - today);

          return diffA - diffB;
        });
      return sortedEventsData.map((event) => (
        event.status != 'archived' ?
          event.name.toLowerCase().includes(filter.toLowerCase())
          || event.description.toLowerCase().includes(filter.toLowerCase())
          || event.address.toLowerCase().includes(filter.toLowerCase())
            ?
            <div
              className='Card-Container'
              key={uuidv4()}>
              <EventCard
                key={uuidv4()}
                event={event}
                convertTime={convertTime}
                cardType={'event'}
                deleteItem={deleteItem}
                archiveItem={archiveItem}
              />
            </div> : null
          : null
      ));
    } catch (err) {
      alert(err);
    }
  };
  if (userVerified) {
    if (accessLevel === 'admin') {
      return (
        <div className='Dash-Container'>
          <SlideAnimation
            xStart={-45}
            duration={0.5}
            node={'Dash-Menu'}
          >
            <div className='Dash-Menu'>
              <div className='Dash-Menu-Header'>
              5th Ward Portal
              </div>
              <button
                className='Dash-Menu-Button'
                onClick={() => seeSubmissions()}>
              Contacts
              </button>
              <button
                className='Dash-Menu-Button'
                onClick={() => seeEvents()}>
              Events
              </button>
              <button
                className='Dash-Menu-Button'
                onClick={() => seeCalendar()}>
              Calendar
              </button>
              <input
                placeholder='Search'
                className='Search-Bar'
                onChange={(text) => {
                  setDashFilter(text.target.value);
                }}
              />
              <button
                className='Logout-Button'
                onClick={() => changeVerification()}>
              Logout
              </button>
            </div>
          </SlideAnimation>
          <div className='Dash-Body'>
            {
              currentView === 'submissions' ?
                ContactDataRender(dashFilter) : 
                currentView === 'events' ?
                  EventsDataRender(dashFilter) :
                  currentView === 'calendar' ?
                    <EventsCalendar eventsData={eventsData} /> : null
            }
          </div>
        </div>
      );
    } else if (accessLevel === 'user' || 'none' || null) {
      return (
        <div className="User-Dash">
          <div className="User-Dash-Paragraph">
            Welcome, this portal is currently under construction.
          </div>
          <div className='Dash-Header'>
            <button
              className='Logout-Button'
              onClick={() => changeVerification()}>
            Logout
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  } else if (!userVerified) {
    return <Login verifyUser={changeVerification} />;
  }
}
export default Dashboard;