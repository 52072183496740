import React from 'react';
import './HomeBody.scss';
import Events from './Events';
import {Link} from 'react-router-dom';
export default function Body() {
  return (
    <div className="Body-Container">
      <Events />
      <Link
        className='Contact-Button'
        to={'/contact'}
      >
          Submit An Event
      </Link>
    </div>
  );
}
