import React from 'react';
// Style Imports
import './App.scss';
// Import Https Redirect
import HttpsRedirect from 'react-https-redirect';
// Import Pages
import * as Pages from './pages';
// Import Components
import * as Components from './components';
//  Router Imports
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
// Firebase
import {initializeApp} from 'firebase/app';
import firebaseConfig from './Config/firebase';
import firebase from 'firebase/compat/app';
// Scroll to Top Function for navigation
import ScrollToTop from './functions/ScrollToTop';
// i18n Language Function
import './functions/i18n';
// Google Analytics
import ReactGA from 'react-ga4';

function App() {
  if (firebase.apps.length < 1) {
    initializeApp(firebaseConfig);
    ReactGA.initialize('G-C4M1QW5QDE');
  }
  return (
    <HttpsRedirect>
      <div className="App">
        {/* Main Application Router */}
        <Router>
          <Components.Navigator />
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Pages.Home />} />
              <Route path="/about" element={<Pages.About />} />
              <Route path="/contact" element={<Pages.Contact />} />
              <Route path="/services" element={<Pages.Services />} />
              {/* <Route path='/participatory-budget' element={<Pages.Budget />} />
              <Route path='/participatory-budgeting' element={<Pages.Budget />} /> */}
              <Route path="/Privacy" element={<Pages.Privacy />} />
              <Route path="/dash" element={<Pages.Dashboard />} />
              <Route path="*" exact element={<Pages.Redirect />} />
            </Routes>
            <Components.Footer />
          </ScrollToTop>
        </Router>
      </div>
    </HttpsRedirect>
  );
}

export default App;
