import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './Footer.scss';
import aldermanLogo from '../../assets/images/rjp logo transparent.png';
import SlideAnimation from '../../animations/SlideAnimation';
class Footer extends Component {
  render() {
    return (
      <div className='Footer-Container'>
        <SlideAnimation
          xStart={-150}
          duration={0.6}
          node={'Footer-Left'}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              window.open('/dash', '_blank');
            }}
            className='Footer-Left'>
            <img src={aldermanLogo}
              className='Footer-Portal'
            />
          </div>
        </SlideAnimation>
        <div className='Footer-Right'>
          <div>
            <div>6700 W. 26th St.</div>
            <div>Berwyn, IL 60402</div>
            
          </div>
          <div>
          (708) 749-6405
          </div>
          <a
            className='Footer-Email'
            onClick={(e) => {
              e.preventDefault();
              window.open('mailto:5thward@berwyn-il.gov', '_blank');
            }}
          >
          5thward@berwyn-il.gov
          </a>
          <Link
            className='Footer-Email'
            to='/privacy'
          >
           Privacy Policy
          </Link>
        </div>
      </div>
    );
  }
}

export default Footer;
      