import React from 'react';
import {ReCaptcha} from 'react-recaptcha-google';
import './CheckUser.scss';
import PropTypes from 'prop-types';
function CheckUser(props) {
  return (
    <div
      className='Recaptcha-Container'
    >
      <ReCaptcha
        render="explicit"
        sitekey="6LeNKvojAAAAAIFeHkgr5hvv9ACxSslAfhakyfuo"
        verifyCallback={(res) => {props.changeRecaptcha(res);}}
      />
    </div>
  );
}
CheckUser.propTypes = {
  changeRecaptcha: PropTypes.func
};
export default CheckUser;