import React, {Component} from 'react';
import * as Components from './components';
import ReactGA from 'react-ga4';

export class About extends Component {
  render() {
    ReactGA.send({hitType: 'pageview', page: '/about', title: 'About'});
    return (
      <div>
        <Components.AboutBody />
      </div>
    );
  }
}

export default About;