import React, {useState} from 'react';
import PicnicImg from '../images/picnic.jpg';
import InterGenImg from '../images/generation.jpg';
import PlantClubImg from '../images/plantclub.jpg';
import TrainingImg from '../images/training.jpg';
import YouthImg from '../images/youth.jpg';

import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

function OptionsInfo(props) {
  const {t} = useTranslation();
  const [currentOption, setCurrentOption] = useState('Project A');
  function VotingOption(option, index) {
    return (
      <div key={index}>
        <div
          onClick={() => {
            setCurrentOption(option);
          }}
          className='Vote-Grid-Option'>
          {option.includes('Project A') ? t('projectA1') :
            option.includes('Project B') ? t('projectB1') :
              option.includes('Project C') ? t('projectC1') :
                option.includes('Project D') ? t('projectD1') :
                  option.includes('Project E') ? t('projectE1') : 
                    null
          }
        </div>
      </div>
    );
  }
  return (
    <div
      className='Options-Info-Container'
    >
      <img className='Voting-Selection-Image'
        src={
          currentOption === props.votingOptions[0] ? PicnicImg :
            currentOption === props.votingOptions[1] ? InterGenImg :
              currentOption === props.votingOptions[2] ?  PlantClubImg :
                currentOption === props.votingOptions[3] ? TrainingImg :
                  currentOption === props.votingOptions[4] ? YouthImg :
                    PicnicImg
        }
      />
      <div className='Options-Grid'>
        {
          props.votingOptions.map((option, index) => {
            return (
              VotingOption(option, index)
            );
          })
        }
      </div>
      <section className='Voting-Option-Info-Section'>
        <h2 className='Voting-Option-Info-Header'>
          {currentOption.includes('Project A') ? t('projectA1') :
            currentOption.includes('Project B') ? t('projectB1') :
              currentOption.includes('Project C') ? t('projectC1') :
                currentOption.includes('Project D') ? t('projectD1') :
                  currentOption.includes('Project E') ? t('projectE1') : 
                    null
          }
        </h2>
        <p className='Voting-Option-Info-Text'>
          {currentOption.includes('Project A') ? t('projectA2') :
            currentOption.includes('Project B') ? t('projectB2') :
              currentOption.includes('Project C') ? t('projectC2') :
                currentOption.includes('Project D') ? t('projectD2') :
                  currentOption.includes('Project E') ? t('projectE2') : 
                    null
          }
        </p>
        <p className='Voting-Option-Info-Cost'>
          {currentOption.includes('Project A') ? t('projectACost') :
            currentOption.includes('Project B') ? t('projectBCost') :
              currentOption.includes('Project C') ? t('projectCCost') :
                currentOption.includes('Project D') ? t('projectDCost') :
                  currentOption.includes('Project E') ? t('projectECost') : 
                    null
          }
        </p>
      </section>
    </div>
  );
}
OptionsInfo.propTypes = {
  votingOptions: PropTypes.array
};
export default OptionsInfo;
