import * as Components from './components';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptcha: ''
    };
  }
  componentDidMount() {
    this.setState({recaptcha: ''});
  }
  changeRecaptcha = (change) => {
    this.setState({recaptcha: change});
  };
  render() {
    if (this.state.recaptcha === '') {
      ReactGA.send({hitType: 'pageview', page: '/recaptcha', title: 'Recaptcha'});
      return (
        <Components.CheckUser changeRecaptcha={this.changeRecaptcha} />
      );
    } else if (this.state.recaptcha !== '') {
      ReactGA.send({hitType: 'pageview', page: '/contact', title: 'Contact'});
      return (
        <Components.Form />
      );
    }
  }
}

Contact.propTypes = {
  app: PropTypes.object
};

export default Contact;
