import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword} from 'firebase/auth';
import './Login.scss';
import {getDatabase, update, ref} from 'firebase/database';
import ReactGA from 'react-ga4';

export default function Login(props) {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userData, setUserData] = useState('');
  const auth = getAuth();
  const db = getDatabase();
  ReactGA.send({hitType: 'pageview', page: '/login', title: 'Login'});
  useEffect(() => {
    if (userData) {
      props.verifyUser();
    }
    return () => {
      // props.verifyUser();
    };
  }, [userData]);
  const signUp = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, userEmail, userPassword)
      .then(res => {
        setUserData(res.user);
        saveUserData(res.user);
      })
      .catch((err) =>
        alert('Sign Up Failed', err.message)
      );
  };
  const saveUserData = (user) => {
    update(ref(db, 'users/' + user.uid), {
      email: user.email,
      accessLevel: 'none'
    });
  };
  const login = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, userEmail, userPassword)
      .then(res => {
        setUserData(res.user);
      })
      .catch((err) =>
        alert('Login Failed', err.message)
      );
  };
  return (
    <div className="Contact-Body-Container">
      <div className='Login-Header'>5th Ward Portal</div>
      <form className="Contact-Form">
        <input
          autoComplete='email'
          className="Contact-Form-Input"
          type="text"
          placeholder="Email"
          onChange={(change) => {
            setUserEmail(change.target.value);
          }}
        />
        <input
          autoComplete='current-password'
          className="Contact-Form-Input"
          type="password"
          placeholder="Password"
          onChange={(change) => {
            setUserPassword(change.target.value);
          }}
        />
        <button
          onClick={login}
          className="App-Button"
        >
          Login
        </button>
        <button
          onClick={signUp}
          className="App-Button"
        >
          Sign Up
        </button>
      </form>
    </div>
  );
}

Login.propTypes = {
  verifyUser: PropTypes.func
};