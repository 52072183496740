import {useLocation} from 'react-router-dom';
import {useLayoutEffect} from 'react';
/**
 * @returns Scroll to Top Component for
 * auto scrolling to the top on page changes.
 */
const ScrollToTop = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
export default ScrollToTop;
