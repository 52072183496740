import './Form.scss';
import React, {useState} from 'react';
import TimePicker from 'react-time-picker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// Firebase Imports
import {uuidv4} from '@firebase/util';
import {getApp} from 'firebase/app';
import {getFirestore, doc, setDoc} from 'firebase/firestore';
import {getDatabase, update, ref} from 'firebase/database';
import {useTranslation} from 'react-i18next';

export default function FormBody() {
  const {t} = useTranslation();
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [topic, setTopic] = useState('');
  const [eventName, setEventName] = useState();
  const [eventAddress, setEventAddress] = useState();
  const [eventDate, setEventDate] = useState();
  const [eventTime, setEventTime] = useState();
  const [eventDescription, setEventDescription] = useState();
  const [eventURL, setEventURL] = useState();
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const app = getApp();
  const db = getDatabase();
  const firestore = getFirestore(app);
  const dateOptions = {
    timeZone: 'America/Chicago',
  };
  function isValidUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  const saveData = async (submissionID) => {
    var dateTime = new Date().toLocaleString('en-US', dateOptions);
    // * SAVE DATA
    var saveContactToDB = () => {
      if (topic !== 'Topic') {
        update(ref(db, 'contact-submissions/' + `${submissionID}`), {
          name: userName,
          topic: topic,
          email: email,
          address: address,
          phone: phone,
          message: topic === 'Event' ? eventDescription : message,
          timeStamp: dateTime,
        });
      }
    };
    var saveContactToFireStore = (submissionID) => {
      console.log('Saving to Firestore', userName);
      if (topic !== 'Topic') {
        try {
          setDoc(doc(firestore, 'contact-submissions/' + `${submissionID}`), {
            name: userName,
            topic: topic,
            email: email,
            address: address,
            phone: phone,
            message: topic === 'Event' ? eventDescription : message,
            timeStamp: dateTime,
            status: 'waiting'
          });
        } catch (err) {
          alert(err);
        }

      }
    };
    var saveEvent = (submissionID) => {
      update(ref(db, 'events/' + `${submissionID}`), {
        userName: userName,
        userEmail: email,
        timeStamp: dateTime,
        name: eventName,
        address: eventAddress,
        date: eventDate.toDateString(),
        startTime: eventTime,
        description: eventDescription,
        status: 'applied',
        url: eventURL
      });
    };
    if (topic === 'Event') {
      if (isValidUrl(eventURL) && userName !== '' && email !== '' && phone !== '' && eventTime && topic !== 'Topic') {
        try {
          var submissionID = uuidv4();
          await Promise.all([saveContactToDB(submissionID), saveContactToFireStore(submissionID), saveEvent(submissionID)]);
          setDataSubmitted(true);
        }
        catch {
          alert('Submission Error', 'Please Try Again later.');
        }
      } else {
        alert('Please enter a valid topic, URL, your name, email, start time and phone number to submit an event.');
      }
    } else if (topic !== 'Event') {
      if (userName !== '' && email !== '' && phone !== '' && topic !== 'Topic') {
        try {
          await Promise.all([saveContactToDB(),saveContactToFireStore()]);
          setDataSubmitted(true);
        }
        catch {
          alert('Submission Error', 'Please Try Again later.');
        }
      } else if (!isValidUrl(eventURL)) {
        alert('Please enter a valid topic, name, email and phone number to submit a contact form.');
      }
    }
    
  };
  const submitData = (e) => {
    e.preventDefault();
    if (!dataSubmitted) {
      saveData();
    } else if (dataSubmitted) {
      alert('Form Already Submitted');
    }
  };
  if (!dataSubmitted) {
    return (
      <div className="Contact-Body-Container">
        <h2 className='Contact-Header'>{t('contactHeader')}</h2>
        <form className="Contact-Form">
          <input
            maxLength={100}
            className="Contact-Form-Input"
            type="text"
            placeholder={t('formInputOne')}
            onChange={(change) => {
              setUserName(change.target.value);
            }}
          />
          
          <input
            maxLength={100}
            className="Contact-Form-Input"
            type="email"
            placeholder={t('formInputTwo')}
            onChange={(change) => {
              setEmail(change.target.value);
            }}
          />
          <input
            maxLength={100}
            className="Contact-Form-Input"
            type="text"
            placeholder={t('formInputThree')}
            autoComplete='address'
            onChange={(change) => {
              setAddress(change.target.value);
            }}
          />
          <input
            maxLength={100}
            className="Contact-Form-Input"
            type="text"
            placeholder={t('formInputFour')}
            onChange={(change) => {
              setPhone(change.target.value);
            }}
          />
          <select
            onChange={(selection) => {
              setTopic(selection.target.value);
            }}
            className='Contact-Form-Select'
          >
            <option>{t('formInputSelect')}</option>
            <option>Event</option>
            <option>Amend Vote - PB</option>
            <option>Public Safety</option>
            <option>Housing</option>
            <option>Streets and Alleys</option>
            <option>Waste Management</option>
            <option>Neighbor Conflict</option>
            <option>Sewer and Water Line</option>
            <option>Tree Planting, Care or Removal</option>
            <option>Permits (Building, Block Party, Garage Sales, etc)</option>
            <option>Mutual Aid & Collective Care</option>
            <option>Other City Services (Bills, Filing, etc)</option>
          </select>
          {
            topic === 'Event' ? (
              <span
                className='Event-Form'
              >
                <input
                  maxLength={100}
                  className="Event-Input"
                  type="email"
                  placeholder="Event Name"
                  onChange={(change) => {
                    setEventName(change.target.value);
                  }}
                />
                <input
                  maxLength={60}
                  className="Event-Input"
                  type="text"
                  placeholder="Event Address"
                  onChange={(change) => {
                    setEventAddress(change.target.value);
                  }}
                />
                <div className="Date-Time-Container">
                  <DatePicker
                    className='Event-Date-Input'
                    selected={eventDate}
                    onChange={
                      (date) => setEventDate(date)
                    }
                    placeholderText="Event Date"
                    todayButton={true}
                  />
                  <TimePicker
                    locale='en-US'
                    disableClock
                    format="hh:mm a"
                    value={eventTime}
                    className='Event-Time-Input'
                    placeholder="Event Time"
                    onChange={(change) => {
                      setEventTime(change);
                    }}
                  />
                </div>
                <textarea
                  maxLength={500}
                  className="Event-Input"
                  type="text"
                  placeholder="Event Description"
                  onChange={(change) => {
                    setEventDescription(change.target.value);
                  }}
                />
                <input
                  maxLength={100}
                  className="Event-Input"
                  type="text"
                  placeholder="Event URL"
                  onChange={(change) => {
                    setEventURL(change.target.value);
                  }}
                />
              </span>
            ) : null
          }
          {
            topic !== 'Event' ? (
              <textarea
                maxLength={500}
                className="Contact-Form-Input-Area"
                placeholder={t('formInputMessage')}
                onChange={(change) => {
                  setMessage(change.target.value);
                }}
              />
            ) : null
          }
          <button
            onClick={submitData}
            className="App-Button"
          >
            {t('formSubmit')}
          </button>
        </form>
      </div>
    );
  } else if (dataSubmitted) {
    return (
      <div className='Data-Submitted-Container'>
        <div className='Thank-You-Header'>
          {t('thankYou')}
        </div>
        <div className='Thank-You-Text'>
          We will get back to you as soon as possible.
        </div>
      </div>
    );
  } 
}
