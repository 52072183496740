import React from 'react';
import './Privacy.scss';

export default function Privacy() {
  return (
    <div className='Privacy-Page'>
      <h1 className='Privacy-Header'>Berwyn 5th Ward Privacy Policy</h1>
      <p className='Privacy-Paragraph'>
  This Privacy Policy describes how the Berwyn 5th Ward website
  (www.berwyn5thward.org) collects, uses, and discloses your personal information
  when you use our website.
      </p>
      <h2 className='Privacy-Sub-Header'>Information We Collect</h2>
      <p className='Privacy-Paragraph'>
  We collect two types of information on our website:
      </p>
      <ul>
        <li>
    **Information you directly provide:** This includes information you submit
    through our contact forms and event submissions. This may include your name,
    email address, phone number, and any other information you choose to share.
        </li>
        <li>
    **Information collected automatically:** When you visit our website, we
    automatically collect certain information about your device and browsing
    activity. This information may include your IP address, browser type,
    operating system, referring URL, pages visited on our website, and the time
    and date of your visit. We collect this information using Google Analytics.
        </li>
      </ul>
      <h2 className='Privacy-Sub-Header'>Use of Cookies</h2>
      <p className='Privacy-Paragraph'>
    We use cookies to collect information about your use of our website. Cookies
    are small data files that are placed on your device by a website when you
    visit it. They allow the website to recognize your device and remember
    information about your visit. You can control or disable cookies through your
    browser settings. However, if you disable cookies, you may not be able to use
    all of the features of our website.
      </p>
      <h2 className='Privacy-Sub-Header'>Use of Google Analytics</h2>
      <p className='Privacy-Paragraph'>
          We use Google Analytics to track user behavior on our website. Google
          Analytics collects information such as how often users visit our site, what
          pages they visit, and what other sites they used prior to coming to our site.
          We use this information to improve the content and functionality of our
          website. Google Analytics collects only the IP address assigned to you on the
          date you visit our site, rather than your name or other identifying
          information. We do not combine the information collected through Google
          Analytics with personally identifiable information. You can learn more about
          Google Analytics and how to opt out of cookies used for advertising at:{' '}
        <a className='GA-Support-Link' href="https://support.google.com/analytics/answer/7318509?hl=en">
          Support for Google Analytics
        </a>
      </p>
      <h2 className='Privacy-Sub-Header'>Use of Your Information</h2>
      <p className='Privacy-Paragraph'>
        We use the information we collect to:
      </p>
      <ul>
        <li>Operate and maintain our website</li>
        <li>Respond to your inquiries and requests</li>
        <li>Send you information about upcoming events and news</li>
        <li>Analyze how users interact with our website</li>
      </ul>
      <h2 className='Privacy-Sub-Header'>Disclosure of Your Information</h2>
      <p className='Privacy-Paragraph'>
        We will not share your personal information with any third party except in the
        following cases:
      </p>
      <ul>
        <li>To comply with legal or regulatory requirements</li>
        <li>To protect the security or integrity of our website</li>
        <li>
          To fulfill a service you have requested (e.g., if you submit a contact form,
          we may share your information with a third party to respond to your request)
        </li>
      </ul>
      <h2 className='Privacy-Sub-Header'>Data Retention</h2>
      <p className='Privacy-Paragraph'>
        We will retain your personal information for as long as necessary to fulfill
        the purposes described in this Privacy Policy, unless a longer retention
        period is required or permitted by law.
      </p>
      <h2 className='Privacy-Sub-Header'>Your Rights</h2>
      <p className='Privacy-Paragraph'>
        You have certain rights regarding your personal information, including:
      </p>
      <ul>
        <li>The right to access your personal information</li>
        <li>The right to rectify inaccurate personal information</li>
        <li>The right to request the erasure of your personal information</li>
        <li>The right to object to the processing of your personal information</li>
      </ul>

      <h2 className='Privacy-Sub-Header'>Contact Us</h2>

      <p className='Privacy-Paragraph'>
        If you have any questions about this Privacy Policy or your rights, please
        contact us at 5thward@berwyn-il.gov.
      </p>

      <h2 className='Privacy-Sub-Header'>Changes to this Privacy Policy</h2>

      <p className='Privacy-Paragraph'>
      We may update this Privacy Policy from time to time. We will post any changes
      on this page. We encourage you to review this Privacy Policy periodically
      </p>
    </div>
  );
}
