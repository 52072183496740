export const englishTranslation = {
  // Nav Bar
  navOne: 'Berwyn\'s Fifth Ward',
  navTwo: 'About',
  navThree: 'Contact',
  navFour: 'Services',
  // Home Page
  heroOne: 'The Fifth Ward',
  eventsHeader: 'Ward Events',
  contactButton: 'Submit Event',
  // About Page
  paraOne: 'Rob was elected 5th Ward Alderman in April of 2021. Prior to beginning his four-year term on May 11th, 2021, Rob served on the Board of Education for Berwyn North School District 98. He is also the co-founder of the Berwyn Free Library Initiative, a grassroots organization that promotes bi-literacy, community building and access to resources for all. Rob is an accomplished non-profit leader and community organizer who has dedicated his life and career to creating opportunities for others and moving folks to action.',
  paraTwo: 'Rob began his career in the non-profit sector working in youth development and career services. He spent several years working with youth from the Berwyn/Cicero area and throughout that time coached young adults to discover their career pathway, trained as a circle keeper and developed as a facilitator.',
  paraThree: 'In his current role at Chicago Cares, he works on the civic and community relations team where he partners with organizations all throughout the city of Chicago to support their quality of life plans and move volunteers to civic action.',
  paraFour: 'Rob is a lifelong Berwyn resident and proud Latinx leader, who is committed to using his resources and gifts to inspire more people - especially those not usually represented - to have a seat and voice at the table. Rob lives on the north side of Berwyn, with his wife and three children.',
  // Contact Page 
  contactHeader: 'Contact the Alderman',
  formInputOne: 'Name',
  formInputTwo: 'Email',
  formInputThree: 'Full Address',
  formInputFour: 'Phone',
  formInputSelect: 'Topic',
  formInputMessage: 'Message',
  formSubmit: 'Send',
  thankYou: 'Thank You!',
  // Services Page
  serviceOne: 'Water Bill',
  serviceTwo: 'Block Party',
  serviceThree: 'Waste Management',
  serviceFour: 'Request a Tree',
  serviceFive: 'Register to Vote',
  serviceSix: 'Senior Services',
  serviceSeven: 'Rat Abatement',
  serviceEight: 'Tax Appeals',
  // Budgeting 2024
  projectA1: 'Project A: 5th Ward Picnic',
  projectA2: 'A community picnic for 5th ward residents & friends. The picnic would include family activities, music and fun for all ages.',
  projectACost: '$2,000',
  projectB1: 'Project B: Intergenerational Fun',
  projectB2: 'A series of events for older adults and youth in the community to come together, build connection and support one another.',
  projectBCost: '$1,500',
  projectC1: 'Project C: Native Plant Club',
  projectC2: 'Funding & support to start a native plant club in our neighborhood. This would include plants, native plant education and beautification projects on street corners.',
  projectCCost: '$1,500',
  projectD1: 'Project D: Civic Leadership Training',
  projectD2: 'Funding to train volunteers on how to serve as court watchers. The training would include best practices and resources to support residents who are faced with legal or financial challenges.',
  projectDCost: '$1,000',
  projectE1: 'Project E: Youth Civic Spirit Award',
  projectE2: 'A grant for an 8th grade student in Berwyn who embodies civic spirit, service, and community. Teachers, administrators, coaches and community leaders can nominate a youth for this award. ',
  projectECost: '$750',
};