import React from 'react';
import PropTypes from 'prop-types';
import {getDatabase, get, child, ref, update} from 'firebase/database';

function ContactCard(props) {
  const db = getDatabase();
  const dbRef = ref(db);
  const updateContactStatus = (item) => {
    get(child(dbRef, 'contact-submissions/')).then((snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
          if (childData.email === props.contact.email && childData.timeStamp === props.contact.timeStamp) {
            update(ref(db, 'contact-submissions/' + `${childKey}/`), {
              status: item
            });
          }
        });
      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error(error);
    });
  };
  return (
    <div
      className={'Dash-Card'}
    >
      <div className="Dash-Card-Name">{props.contact.name}</div>
      <div
        className={
          props.contact.topic.includes('Event') ? 
            'Dash-Card-Topic-1'
            : props.contact.topic.includes('Safety') ?
              'Dash-Card-Topic-2'
              : props.contact.topic.includes('Housing') ?
                'Dash-Card-Topic-3'
                : props.contact.topic.includes('Streets') ?
                  'Dash-Card-Topic-4'
                  : props.contact.topic.includes('Waste') ?
                    'Dash-Card-Topic-5'
                    : props.contact.topic.includes('Neighbor') ?
                      'Dash-Card-Topic-6'
                      : props.contact.topic.includes('Sewer') ?
                        'Dash-Card-Topic-7'
                        : props.contact.topic.includes('Tree') ?
                          'Dash-Card-Topic-8'
                          : props.contact.topic.includes('Permits') ?
                            'Dash-Card-Topic-9'
                            : props.contact.topic.includes('Mutual') ?
                              'Dash-Card-Topic-10'
                              : props.contact.topic.includes('Other') ?
                                'Dash-Card-Topic-11'
                                : 'Dash-Card-Topic-1'}
      >
        {props.contact.topic}
      </div>
      <div className="Dash-Card-Cell">{props.contact.timeStamp}</div>
      <div className="Dash-Card-Cell">{props.contact.address}</div>
      <div className="Dash-Card-Cell">{props.contact.phone}</div>
      <div className="Dash-Card-Cell">{props.contact.email}</div>
      <div className="Dash-Card-Cell">{props.contact.message}</div>
      <select
        className={
          props.contact.status === 'waiting' ? 'Dash-Card-Status-1' :
            props.contact.status === 'contacted' ? 'Dash-Card-Status-2' :
              props.contact.status === 'in progress' ? 'Dash-Card-Status-3' :
                props.contact.status === 'resolved' ? 'Dash-Card-Status-4' :
                  'Dash-Card-Status-1'
        }
        value={props.contact.status}
        onChange={(e) => {
          e.preventDefault();
          updateContactStatus(e.target.value);
        }}
      >
        <option value="waiting">Waiting</option>
        <option value="contacted">Contacted</option>
        <option value="in progress">In Progress</option>
        <option value="resolved">Resolved</option>
      </select>
      <button
        onClick={() => props.deleteItem(props.contact, 'contacts')}
        className="Dash-Card-Delete"
      >
        Delete
      </button>
    </div>
  );    
}

ContactCard.propTypes = {
  contact: PropTypes.object,
  deleteItem: PropTypes.func,
  index: PropTypes.number
};

export default ContactCard;