import React, {useEffect, useState} from 'react';
import {getDatabase, ref, onValue} from 'firebase/database';
import {DateTime} from 'luxon';

export default function Events() {
  const [events, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoading) {
      var db = getDatabase();
      // * EVENT DATA
      const events = ref(db, 'events/');
      onValue(events, (snapshot) => {
        setEventsData(Object.values(snapshot.val()));
      });
      setIsLoading(false);
    }
  }, []);
  function convertTime(time) {
    return DateTime.fromFormat(time, 'HH:mm').toFormat('h:mm t').split(' ');
  }
  if (events.length > 0) {
    // Sort events by the absolute difference between their dates and today
    events.sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      const today = new Date();

      // Calculate absolute difference in milliseconds for precise sorting
      const diffA = Math.abs(aDate - today);
      const diffB = Math.abs(bDate - today);

      return diffA - diffB;
    });
    // Return Sorted Events
    return (
      <>
        <h2 className='Events-Header'>Community Events</h2>
        <div className='Event-List-Container'>
          {
            events.map((event, index) =>
              event.status === 'approved' && event.startTime ?
                (
                  <a target="_blank" href={event.url} className='Event-Container' key={index} rel="noreferrer">
                    <div className='Event-Title'>{event.name}</div>
                    <div className='Event-Address'>{event.address}</div>
                    <div className='Event-Description'>{event.description}</div>
                    <div className='Event-Time'>
                      {`${event.date} : ${convertTime(event.startTime)[1]}${convertTime(event.startTime)[2]}`}
                    </div>
                  </a>
                ) : null)
          }
        </div>
      </>
    );
  } else {
    return null;
  }
}