// Render Prop
import React, {useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import PropTypes from 'prop-types';
import {uuidv4} from '@firebase/util';
import {getApp} from 'firebase/app';
import {getFirestore, doc, setDoc} from 'firebase/firestore';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
const dateOptions = {
  timeZone: 'America/Chicago',
};
function VotingForm(props) {
  const {t} = useTranslation();
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [voteData, setVoteData] = useState({});
  const app = getApp();
  const firestore = getFirestore(app);
  var saveContactToFireStore = (formData) => {
    var submissionID = uuidv4();
    var dateTime = new Date().toLocaleString('en-US', dateOptions);
    try {
      setDoc(doc(firestore, 'participatory-budgeting-2024/' + `${submissionID}`), {
        fullName: formData.fullName,
        email: formData.email,
        address: formData.address,
        checked: formData.checked,
        timeStamp: dateTime
      });
    } catch (err) {
      alert(err);
    }
  };
  const ConfirmData = () => {
    return (
      <div className="Confirm-Modal">
        <h2>Confirm Your Participatory Budget Ballot</h2>
        <div className="Confirm-Data">{voteData.fullName}</div>
        <div className="Confirm-Data">{voteData.email}</div>
        <div className="Confirm-Data">{voteData.address}</div>
        <ul className='Confirm-List'>
          {voteData.checked.map((item, index) => {
            return <li key={index} className='Confirm-List-Item'>{item}</li>;
          })}
        </ul>
        <button
          onClick={() => {
            saveContactToFireStore(voteData);
            setShowConfirm(false);
            setDataSubmitted(true);
          }}
          className='Confirm-Button'
          type='submit'>
          Confirm Ballot
        </button>
        <button
          onClick={() => setShowConfirm(false)}
          className='Confirm-Button-Cancel'
          type='cancel'>
          Cancel / Edit
        </button>
      </div>
    );
  };
  if (!dataSubmitted) {
    return (
      <div className='Voting-Form-Container'>
        <h1 className='Voting-Form-Header'>Vote Here (Pick Up To Three):</h1>
        <Formik
          initialValues={{fullName: '', email: '', address: '', checked: []}}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Email Required';
            } else if (!values.fullName) {
              errors.fullName = 'You must add your full name';
            } else if (!values.address) {
              errors.address = 'You must add your address';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            } else if (!values.checked || values.checked.length < 1) {
              errors.checked = 'You must vote for at least 1';
            } else if (values.checked.length > 3) {
              errors.checked = 'You can only vote for 3';
            }
            return errors;
          }}
          onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
              setVoteData(values);
              setShowConfirm(true);
              setSubmitting(false);
            }, 400);
          }}
        >
          {({isSubmitting}) => (
            <Form className='Voting-Form'>
              {showConfirm ? <ConfirmData /> : null}
              {
                props.votingOptions.map((option) => {
                  return (
                    <label key={option}>
                      <Field type="checkbox" name="checked" value={option} />
                      {option.includes('Project A') ? t('projectA1') :
                        option.includes('Project B') ? t('projectB1') :
                          option.includes('Project C') ? t('projectC1') :
                            option.includes('Project D') ? t('projectD1') :
                              option.includes('Project E') ? t('projectE1') : 
                                null
                      }
                    </label>
                  );
                })
              }
              <ErrorMessage name="checked" className='Form-Error' component="div" />
              <Field className='Voting-Form-Field' placeholder='Full Name' type="fullName" name="fullName" />
              <ErrorMessage name="fullName" className='Form-Error' component="div" />
              <Field className='Voting-Form-Field' placeholder='Email' type="email" name="email" />
              <ErrorMessage name="email" className='Form-Error' component="div" />
              <Field className='Voting-Form-Field' placeholder='Address' type="address" name="address" />
              <ErrorMessage name="address" className='Form-Error' component="div" />
              <button className='Voting-Form-Submit' type="submit" disabled={isSubmitting}>
                Vote
              </button>
              <p className='Disclaimer'>
                {'All 5th ward residents 14 and older are eligible to vote. No ID is required. Residents can either choose to vote in person on May 21st or online during the Early Voting Period. Early Voting begins May 7th and runs until May 20th. Only one ballot submission per person. A 5th ward address is required, or we can not count your vote. If you made a mistake, please contact us here to have it amended: '}
                <Link to='/contact'>Amend</Link>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    );
  } else if (dataSubmitted) {
    return (
      <div className='Data-Submitted-Container'>
        <div className='Thank-You-Header'>
          {t('thankYou')}
        </div>
        <div className='Thank-You-Text'>
          For your participation in local government budgeting!
        </div>
        <div className='Thank-You-Text'>
          Results will be available soon!
        </div>
      </div>
    );
  } 
}

VotingForm.propTypes = {
  votingOptions: PropTypes.array
};

export default VotingForm;